export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50')
];

export const server_loads = [2];

export const dictionary = {
		"/(app)": [7,[2]],
		"/(app)/agenda": [8,[2]],
		"/(app)/bo-user": [9,[2]],
		"/(app)/bo-user/create": [11,[2]],
		"/(app)/bo-user/[uid]": [10,[2]],
		"/(app)/client-credentials": [12,[2]],
		"/(app)/client-credentials/create": [13,[2]],
		"/(app)/configuration": [14,[2]],
		"/(app)/connections": [15,[2]],
		"/(app)/contacts": [16,[2]],
		"/(app)/login-sso": [18,[4]],
		"/(app)/login": [17,[3]],
		"/(app)/orders": [19,[2]],
		"/(app)/orders/[slug]": [20,[2]],
		"/(app)/payments": [21,[2]],
		"/(app)/payout": [22,[2]],
		"/(app)/payout/[market]": [23,[2]],
		"/(app)/payout/[market]/[id]": [24,[2]],
		"/(app)/products": [25,[2]],
		"/(app)/products/[sku]": [26,[2]],
		"/(app)/reset-password": [27,[5]],
		"/(app)/set-password": [28,[6]],
		"/(app)/stripe/[[brand]]/payout/[key]": [30,[2]],
		"/(app)/stripe/[[brand]]/preview/[[creditDebitId]]": [31,[2]],
		"/(app)/stripe/[[brand]]": [29,[2]],
		"/(app)/style-genie": [32,[2]],
		"/(app)/style-genie/ai-cocktail": [34,[2]],
		"/(app)/style-genie/ai-personas": [35,[2]],
		"/(app)/style-genie/ai-personas/create": [37,[2]],
		"/(app)/style-genie/ai-personas/[id]": [36,[2]],
		"/(app)/style-genie/boost-products": [38,[2]],
		"/(app)/style-genie/clusters-v2": [40,[2]],
		"/(app)/style-genie/clusters": [39,[2]],
		"/(app)/style-genie/config": [41,[2]],
		"/(app)/style-genie/dash": [42,[2]],
		"/(app)/style-genie/ingestion": [43,[2]],
		"/(app)/style-genie/style-genie-dash": [44,[2]],
		"/(app)/style-genie/[uid]": [33,[2]],
		"/(app)/tenant": [45,[2]],
		"/(app)/tenant/create": [47,[2]],
		"/(app)/tenant/[code]": [46,[2]],
		"/(app)/translations": [48,[2]],
		"/(app)/users": [49,[2]],
		"/(app)/users/[user_id]": [50,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';